import React, { useContext, useState, useEffect } from "react";
import { Link, graphql } from "gatsby";
import { FirebaseContext } from "../../components/Firebase";
import { sluggify } from "../../helpers/sluggify";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faBan, faSearchPlus, faSearchMinus, faSearch } from '@fortawesome/free-solid-svg-icons';

import Seo from "../seo";
import Header from "../organisms/Header";
import Navigator from "../organisms/Navigator";
import DrillList from "../molecules/DrillList";
import Loader from "../atoms/Loader";
import TopoOverlay from "../molecules/TopoOverlay";
import TopoUnderlay from "../molecules/TopoUnderlay";
import Map from "../atoms/Map";
import Copyright from "../atoms/Copyright";
import Footer from "../organisms/Footer";

const Area = (props) => {

  const { user } = useContext(FirebaseContext);
  const [hasAccess, setHasAccess] = useState(false);

  const [panningDisabled, setPanningDisabled] = useState(true);

  const area = props.data.area,
        topos = props.data.allTopo.edges,
        maps = props.data.allMap.edges,
        sortedCrags = [].concat(area.crags).sort((a, b) => a.order > b.order ? 1 : -1);

  useEffect(() => {
    if (user) {
      // access
      if (area.guide.name === 'Montagu') {
        if (user.montaguAccess) {
          setHasAccess(true);
        } else {
          setHasAccess(false);
        }
      } else {
        setHasAccess(false);
      }
    }
  }, [user, area]);

  // to dashed and lowercase
  function toDashedLowercase(title) {
    return title.replace(/\s/g , "-").replace("'" , "").replace("ê", "e").toLowerCase();
  }

  return (
    <>
      {!user ? (
        <Loader />
      ) : (
        <>
          <Seo title={area.guide.name + ' ~ ' + area.name} />
          <Header color={area.color}>
            <Navigator guideName={area.guide.name} title={area.name} nameExtra={area.nameExtra} />
            {area.guide.name === 'Montagu' && user.montaguAccess && (
              <Link to={`/${sluggify(area.guide.name)}/search`} className="search-icon">
                <span>Search</span>
                <FontAwesomeIcon icon={ faSearch } size={ 'lg' } />
              </Link>
            )}
          </Header>
          <main>
            <div className="container">
              <DrillList>
                <li key={area.id} className="more" style={{backgroundColor: `var(--black)`}}>
                  <Link to={`/${sluggify(area.guide.name)}/${sluggify(area.name)}/general-info`}>About {area.name}</Link>
                </li>
                {hasAccess ? (
                  <>
                    {sortedCrags.map(crag => (
                      <li key={crag.id}>
                        <div className="nick" style={{backgroundColor: `${area.color}`}}>
                          <FontAwesomeIcon icon={ faCaretRight } size={ 'lg' } />
                        </div>
                        <Link to={`/${sluggify(area.guide.name)}/${sluggify(area.name)}/${sluggify(crag.name)}`}>
                          {crag.name}
                          {crag.nameExtra && (
                            <sub>
                              {' - ' + crag.nameExtra}
                            </sub>
                          )}
                        </Link>
                      </li>
                    ))}
                  </>
                ) : (
                  <>
                    {sortedCrags.map(crag => (
                      <li key={crag.id} className={!crag.isFree ? "restricted" : null}>
                        {crag.isFree ? (
                          <>
                            <div className="nick" style={{backgroundColor: `${area.color}`}}>
                              <FontAwesomeIcon icon={ faCaretRight } size={ 'lg' } />
                            </div>
                            <Link to={`/${sluggify(area.guide.name)}/${sluggify(area.name)}/${sluggify(crag.name)}`}>{crag.name}</Link>
                          </>
                        ) : (
                          <>
                            <div className="nick" style={{backgroundColor: `var(--medium)`}}>
                              <FontAwesomeIcon icon={ faBan } />
                            </div>
                            <span>
                              {crag.name}
                            </span>
                          </>
                        )}
                      </li>
                    ))}
                  </>
                )}
              </DrillList>

              {topos && topos.sort((a, b) => a.node.order > b.node.order ? 1 : -1).map((item, i) => (
                <div key={i} className="zoomer">
                  {i === 0 && (
                    <hr />
                  )}
                  <div className="zoomer__photo--wrapper" style={{backgroundColor: area.color}}>
                    <TransformWrapper
                      wheel={{ disabled: true }}
                      panning={{ disabled: panningDisabled }}
                      onTransformed={(ref) => {
                        if (ref.state.scale <= 1) {
                          setPanningDisabled(true);
                        } else {
                          setPanningDisabled(false);
                        }
                      }}
                    >
                      {({ zoomIn, zoomOut }) => (
                        <>
                          <div className="zoomer__tools">
                            <button aria-label="Zoom in" onClick={() => {zoomIn()}}><FontAwesomeIcon icon={faSearchPlus} /></button>
                            <button aria-label="Zoom out" onClick={() => {zoomOut()}}><FontAwesomeIcon icon={faSearchMinus} /></button>
                          </div>
                          <TransformComponent>
                            <div className="zoomer__photo">
                              <div className="zoomer__photo__elements" id={`${toDashedLowercase(area.name)}-${i}`}>

                                {item.node.topoOverlay !== null ? (
                                  <svg height="360" version="1.1" style={{zIndex: '100'}} id={`${toDashedLowercase(area.name)}-${i}--overlay`} viewBox={`0 0 ${item.node.imageWidth} 1000`} xmlns="http://www.w3.org/2000/svg"></svg>
                                ): null}

                                {item.node.topoUnderlay !== null ? (
                                  <svg height="360" version="1.1" style={{zIndex: '80'}} id={`${toDashedLowercase(area.name)}-${i}--underlay`} viewBox={`0 0 ${item.node.imageWidth} 1000`} xmlns="http://www.w3.org/2000/svg"></svg>
                                ): null}

                              </div>
                              <GatsbyImage className="zoomer__photo__image" image={getImage(item.node.topo)} alt={area.name} />
                            </div>
                          </TransformComponent>
                        </>
                      )}
                    </TransformWrapper>
                  </div>
                  {item.node.topoOverlay !== null ? (
                    <>
                      <TopoOverlay overlay={item.node.topoOverlay} topo={`${toDashedLowercase(area.name)}-${i}`} />
                    </>
                  ) : null}
                  {item.node.topoUnderlay !== null ? (
                    <>
                      <TopoUnderlay underlay={item.node.topoUnderlay} topo={`${toDashedLowercase(area.name)}-${i}`} />
                    </>
                  ) : null}
                </div>
              ))}

              {maps && maps.sort((a, b) => a.node.order > b.node.order ? 1 : -1).map(item => (
                <div key={item.node.id} className="map">
                  <hr />
                  <Map name={item.node.image} />
                </div>
              ))}

              <Copyright />
            </div>
          </main>
          <Footer />
        </>
      )}
    </>
  )
}

export const query = graphql`
  query AreaQuery($areaId: String!, $toposArray: [String!], $mapsArray: [String!]) {
    area(id: {eq: $areaId}) {
      name
      nameExtra
      color
      guide {
        name
      }
      topos {
        image
      }
      crags {
        id
        name
        nameExtra
        isFree
        order
      }
    }
    allTopo(filter: {id: {in: $toposArray}}) {
      edges {
        node {
          id
          order
          imageWidth
          topoOverlay {
            arrows
            text {
              path
              border
            }
          }
          topoUnderlay {
            approaches {
              linePath
              scramblePath
              behindPath
              tradPath
            }
          }
          topo {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                height: 600
                placeholder: BLURRED
                quality: 100
                formats: [WEBP]
              )
            }
          }
        }
      }
    }
    allMap(filter: {id: {in: $mapsArray}}) {
      edges {
        node {
          id
          image
          order
        }
      }
    }
  }
`;

export default Area;